/// <reference path='../../../../../node_modules/@types/jqueryui/index.d.ts' />
/// <reference path='../../../../../node_modules/@types/jquery.validation/index.d.ts' />
/// <reference path='../../../../../node_modules/@types/bootstrap/index.d.ts' />
/// <reference path='../../../../../node_modules/@types/bootstrap.datepicker/index.d.ts' />

function loadProductsIntoWidget(ajaxUrl: string, widget: JQuery) {
    $.ajax({
        url: ajaxUrl,
        success: (productListHtml: string) => {
            widget.html(productListHtml);
            bindScroller();
            $(document).trigger('cider.core.productsLoaded', widget);
        }
    });
}

function setEnablementOfArrows(scroller) {
    if (scroller.scrollLeft() === 0) {
        scroller.siblings('.leftArrow').addClass("disabled");
    }
    else {
        scroller.siblings('.leftArrow').removeClass('disabled');
    }

    var widget = scroller.closest('.product-scroller-container');

    if ((scroller.scrollLeft() + scroller.width()) >= (widget.data('scrollerFullWidth'))) {
        scroller.siblings('.rightArrow').addClass("disabled");
    }
    else {
        scroller.siblings('.rightArrow').removeClass('disabled');
    }

    var arrows = scroller.siblings('.arrow');
    if (widget.width() > widget.data('scrollerFullWidth')) {
        arrows.addClass('invisible');
    }
    else {
        arrows.removeClass('invisible');
    }
}

function scrollSlider(arrow, scrollRight) {
    var scroller = arrow.siblings('.item-picker');
    var scrollLocation = scroller.scrollLeft();

    var itemWidth = scroller.find('.product-cell').first().outerWidth();

    if (scrollRight) {
        scrollLocation = Math.floor((scrollLocation + itemWidth) / itemWidth ) * itemWidth;
    }
    else {
        scrollLocation = Math.floor((scrollLocation - itemWidth) / itemWidth) * itemWidth;
    }

    scroller.animate({ scrollLeft: scrollLocation }, 200);

    setEnablementOfArrows(scroller);

    if (scroller.scrollLeft() === 0) {
        scroller.siblings('.leftArrow').addClass("disabled");
    }
    else {
        scroller.siblings('.leftArrow').removeClass('disabled');
    }
}

function bindScroller() {
    $('.product-scroller-container').each(function () {
        var scrollerFullWidth = 0;
        $(this).find('.product-cell').each(function () {
            scrollerFullWidth += $(this).outerWidth(true);
        });
        $(this).data('scrollerFullWidth', scrollerFullWidth - 10);

    });

    $('.item-picker').each(function () {
        setEnablementOfArrows($(this));
    });

    $(window).on('resize', () => {
        setEnablementOfArrows($('.item-picker'));
    });

    $(".product-thumbnail-link").bind("tap", function (event) {
        $(document).trigger('cider.catalog.thumbnailLinkClicked', event);
        document.location.href = $(this).data('url');
    });
    $(".product-thumbnail-link").mousedown(function (event) {
        if (event.which !== 1) {
            return false;
        }
        $(this).data('start-time', new Date().getTime());
    }).bind('mouseup', function (event) {
        var endTime = new Date().getTime();
        if (endTime - $(this).data('start-time') < 250) {
            $(document).trigger('cider.catalog.thumbnailLinkClicked', event);
            document.location.href = $(this).data('url');
        }
    });

    $('.item-picker').mousedown(function (event) {
        if (event.which === 1) {
            if ($(event.target).is('.form-control')) {
                return true;
            }

            $(this)
                .data('down', true)
                .data('x', event.clientX)
                .data('scrollLeft', this.scrollLeft);
            return false;
        }
        return true;
    }).mouseup(function () {
            $(this).data('down', false);
        }).mouseleave(function () {
            $(this).data('down', false);
        }).mousemove(function (event) {
            if ($(this).data('down') === true) {
                this.scrollLeft = $(this).data('scrollLeft') + $(this).data('x') - event.clientX;
                setEnablementOfArrows($(this));
            }
        }).css({
            'overflow': 'hidden',
            'cursor': '-moz-grab'
        });

    $(".item-picker").each(function () {
        var scroller = $(this)[0];
        scroller.addEventListener('touchstart', function (e) {
            //e.preventDefault();
            var touch = e.touches[0];

            if ($(this).data('down') !== true) {
                $(this)
                    .data('down', true)
                    .data('x', touch.pageX)
                    .data('scrollLeft', $(this).scrollLeft());
            }
            return false;
        });

        scroller.addEventListener('touchend', function (e) {
            $(this).data('down', false);
        });

        scroller.addEventListener('touchcancel', function (e) {
            $(this).data('down', false);
        });

        scroller.addEventListener('touchmove', function (e) {
            //e.preventDefault();
            var touch = e.touches[0];

            if ($(this).data('down') === true) {
                this.scrollLeft = $(this).data('scrollLeft') + $(this).data('x') - touch.pageX;
                setEnablementOfArrows($(this));
            }
        });
    });

    $('.arrow').each(function() {
        if (!$(this).hasClass('bound-to-action')) {
            $(this).addClass('bound-to-action');
            $(this).on('click', function() {
                scrollSlider($(this), $(this).hasClass('rightArrow'));
            });
        }
    });
}



$(document).ready(() => {
    bindScroller();

    $('.product-browse-search').click(function () {
        //trace selects to get 
        var widget = $(this).closest('.product-browse-widget')
        var level = 1;
        var url;
        var item
        do {
            item = widget.find(".taxonomy-level[data-level='" + level + "'] .taxonomy-term-select:visible:enabled");
            if (item.length != 0 && item.val() != '') {
                url = item.val();
            }
            ++level;
        }
        while (item.length != 0 && item.val() != '');

        window.location.href = url;
    });

    $('.taxonomy-term-select').change(function () {
        var level = parseInt($(this).closest('.taxonomy-level').data('level'));
        var value = $(this).val()
        var id = $(this).find("option[value='" + value + "']").data("content-id");

        if (value == null || value == undefined || value == "") {
            $('.taxonomy-level').each(function () {
                if (parseInt($(this).data("level")) > level) {
                    $(this).find('.taxonomy-term-select').hide();
                    $(this).find('.empty-drop-down').show();
                }
            });
        } else {
            $('.taxonomy-level').each(function () {
                if (parseInt($(this).data("level")) > level + 1) {
                    $(this).find('.taxonomy-term-select').hide();
                    $(this).find('.empty-drop-down').show();
                }
                if (parseInt($(this).data("level")) == level + 1) {
                    //find Id
                    var items = $(this).find(".taxonomy-term-select[data-parent-id='" + id + "']");
                    if (items.length === 0) {
                        $(this).find('.empty-drop-down').show();
                        $(this).find('.taxonomy-term-select').hide();
                    }
                    else {
                        $(this).find('.taxonomy-term-select').hide();
                        $(this).find('.empty-drop-down').hide();
                        items.show();
                        items.val("");
                        //fire event for item?? or do I get for free??
                    }

                }
            });
        }

        if (value == null || value == undefined || value == "" && level == 1) {
            $(this).closest('.product-browse-widget').find('.product-browse-search').prop('disabled', true);
        } else {
            $(this).closest('.product-browse-widget').find('.product-browse-search').prop('disabled', false);
        }
    });

    $('.taxonomy-term-select').first().change();
});